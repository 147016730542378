import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import KareGozluTablo from '../contents/tables/KareGozluTablo'
import DikdortgenGozluTablo from '../contents/tables/DikdortgenGozluTablo'
import OlcuAlert from '../contents/alert/OlcuAlert'

const NervurluTelPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Nervürlü Çelik Hasır Tel İmalatı - Erez Kaynak'
        description='Firmamız nervürlü tel tel kullanarak özel ölçülerde nervürlü çelik hasır tel imalatı yapmaktadır. Nervürlü çelik hasır teller ham malzeme olduğu için sonradan fırın boya yada daldırma boya ile boyanabilir. Tel kalınlığı 5mm den 8mm tel kalınlığına kadar nervürlü çelik hasır tel imalatını yapabiliyoruz.'
        keywords='nervürlü çelik hasır,nervürlü çesan,çesan,5lik nervürlü,nervürlü kafes tel,nervürlü tel,6lık nervürlü,nervürlü hasır tel,nervürlü tel kafes,nervürlü tel hasır,nervürlü çesan fiyatları,nervürlü çesan ölçüleri,nervürlü çesan kullanım alanları,nervürlü çesan istanbul'
        url='nervurlu-celik-hasir-tel-imalati'
        imgurl={getImageByName('nervurlucelikhasir').sm.src}
      />
      <ImgLightBox ref={lightBoxRef} gallery={[getImageByName('nervurlucelikhasir').original.src]} />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Nervürlü Çelik Hasır Tel İmalatı' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Firmamız <strong>nervürlü tel kullanarak</strong> özel ölçülerde <strong>nervürlü çelik hasır tel</strong> imalatı
                yapmaktadır.
              </p>
              <p>
                <strong>Nervürlü çelik hasır teller</strong> ham malzeme olduğu için sonradan fırın boya ya da daldırma boya ile
                boyanabilir.
              </p>
              <p>
                Tel kalınlığı 4mm'den 8mm tel kalınlığına kadar <strong>nervürlü çelik hasır tel</strong> imalatını yapabiliyoruz.
              </p>
              <p>
                Göz aralığı standart olarak merkezden merkeze 15mm, 20mm, 25mm ve katlarında <strong>nervürlü çelik hasır tel</strong>{' '}
                imalatı yapılabilir.
              </p>
              <p>
                15mm katı olan 15x90mm veya 25mm katı olan 50x125mm gibi istediğiniz her ölçüde <strong>nervürlü çelik hasır tel</strong>{' '}
                yapabilmekteyiz.
              </p>
              <p>
                Özel göz aralıklarında (örn; 26,3×37,5mm vb.) yeni kalıp yaparak hızlı bir şekilde müşterilerimizin ihtiyacı olan{' '}
                <strong>nervürlü çelik hasır telleri</strong> uygun bir maliyet ile üretiyoruz.
              </p>
              <p>
                Nervürlü çelik hasır tellere Elektrostatik toz fırın boya işlemi yapılabilmektedir. Standart yada özel renk seçenekleri ile
                hasır teller elektrostatik toz boya ile boyanmaktadır. Firmamız bünyesinde boyasız olarak hasır tel imalatı gerçekleştirip,
                çalışmış olduğumuz elektrostatik toz boya yapan firmalar ile ihtiyacınız karşılanmaktadır.
              </p>
              <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
            </ContentText>
            <Box textAlign='center'>
              <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('nervurlucelikhasir')} alt='Özel hasır tel imalatı' />
            </ImgWrapper>
            <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />

      <Box p={3}>
        <OlcuAlert />
      </Box>
      <Box p={3} mb={5}>
        <KareGozluTablo />
      </Box>
      <Box p={3} mb={5}>
        <DikdortgenGozluTablo />
      </Box>
    </>
  )
}

export default NervurluTelPage
